import { ArrowRightCircleIcon, EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import { Fragment, useEffect, useState } from "react";
import Header from "../../../../../Components/Header";
import {
    ArrowUpTrayIcon,
    EllipsisVerticalIcon,
    EyeIcon,
    InformationCircleIcon,
    PlayIcon
} from '@heroicons/react/20/solid';
import Http from "../../../../../Utils/Http";
import Notifier from "../../../../../Utils/Notifier";
import Container from "../../../../../Components/Container";
import { CurrencyText, DateText, Input, NumberText, Paging, ShortenInText } from "../../../../../Utils/Template";
import { Listbox, Menu, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import Translate from "../../../../../Utils/Translate";
import F from "../../../../../Utils/Functions";
import axios from "axios";
import useDocumentTitle from "../../../../../Utils/DocumentTitle";
import { useParams } from "react-router-dom";
import { VerificationStatus } from "cleared-verification-client";
import "cleared-verification-client/dist/styles/no-tailwind.css";

const movementStatuses = [
    { "label": "(empty)", "value": "" },
    { "label": "En Route to FL", "value": "new" },
    { "label": "FL Warehouse", "value": "warehouse" },
    { "label": "In Flight", "value": "inFlight" },
    { "label": "Clearing", "value": "clearing" },
    { "label": "Ready", "value": "ready" },
    { "label": "En Route to You", "value": "delivering" },
    { "label": "Delivered", "value": "delivered" },
    { "label": "Removed", "value": "removed" },
];
const paymentStatuses = [
    { "label": "(empty)", "value": "" },
    { "label": "Paid", "value": "paid" },
    { "label": "Unpaid", "value": "unpaid" },
];
const etaStatuses = [
    { "label": "(empty)", "value": "" },
    { "label": "On Time", "value": "onTime" },
    { "label": "Delayed", "value": "delayed" },
];
const invoiceStatuses = [
    { "label": "(empty)", "value": "" },
    { "label": "Received", "value": "received" },
    { "label": "Outstanding", "value": "outstanding" },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Manage(props) {

    let { customerId, pageId } = useParams();

    const pageTitles = {
        details: 'Personal Details',
        packages: 'Packages',
        contacts: 'Contacts',
        addresses: 'Addresses',
        transactions: 'Transactions',
    }

    useDocumentTitle(pageTitles[pageId || 'details']);

    const [memberInfo, setMemberInfo] = useState({});
    const [originalMemberInfo, setOriginalMemberInfo] = useState({});
    const [page, setPage] = useState('details');
    const [fileName, setSelectedFileName] = useState('No file selected yet');
    const [hasFile, setHasFile] = useState(false);
    const [packages, setPackages] = useState([]);
    const [transactions, setTransactions] = useState([]);

    const tabs = [
        { name: 'Personal Details', href: `/members/${customerId}/manage/details`, current: pageId === 'details' || pageId === '' || !pageId },
        { name: 'Packages', href: `/members/${customerId}/manage/packages`, current: pageId === 'packages' },
        { name: 'Addresses', href: `/members/${customerId}/manage/addresses`, current: pageId === 'addresses' },
        { name: 'Statement', href: `/members/${customerId}/manage/statement`, current: pageId === 'statement' },
        { name: 'Transactions', href: `/members/${customerId}/manage/transactions`, current: pageId === 'transactions' }
    ]

    const handleChange = e => setMemberInfo({ ...memberInfo, [e.target.name]: e.target.value });

    useEffect(() => {
        fetchMember();
        setPage(pageId);
    }, []);

    const fetchMember = async (id) => {
        const response = await Http.request({ uri: `/customers/${customerId || id}/get`, body: {} });
        if (response.notification) {
            Notifier(response.notification);
        }
        if (response.result === 'success') {
            setMemberInfo(response.body);
            if (pageId === 'packages') fetchPackages();
            if (pageId === 'transactions') fetchTransactions();
        }
    };

    const fetchPackages = async p => {
        let body = { query: { customerId }, paging: p || pagination.packages, keywords: keywords.packages };
        const response = await Http.request({ uri: `/packages/browse`, body });
        if (response.notification) {
            Notifier(response.notification);
        }
        setPackages(response.body.packages);
        setPagination({ ...pagination, packages: response.body.pagination });
    }

    const fetchTransactions = async t => {
        let body = { query: { customerId }, paging: t || pagination.transactions, keywords: keywords.transactions };
        const response = await Http.request({ uri: `/transactions/browse`, body });
        if (response.notification) {
            Notifier(response.notification);
        }
        setTransactions(response.body.transactions);
        setPagination({ ...pagination, transactions: response.body.pagination });
    }

    const saveMemberInfo = async () => {
        let body = memberInfo;
        const response = await Http.request({ uri: `/customers/${customerId}/update`, body });
        if (response.notification) {
            Notifier(response.notification);
        }
        if (response.result === 'success') {
        }
    };

    const applyCharges = async (packageId) => {
        let body = { packageId };
        const response = await Http.request({ uri: `/payments/${packageId}/update-charges`, body });
        if (response.notification) {
            Notifier(response.notification);
        }
        fetchPackages();
    };

    const applyAllCharges = async (customerId) => {
        let body = { customerId };
        const response = await Http.request({ uri: `/payments/${customerId}/update-all-charges`, body });
        if (response.notification) {
            Notifier(response.notification);
        }
        fetchPackages();
    };

    const cancelCharges = async (packageId) => {
        let body = { packageId };
        const response = await Http.request({ uri: `/payments/${packageId}/cancel-charges`, body });
        if (response.notification) {
            Notifier(response.notification);
        }
        fetchPackages();
    };

    const cancelAllCharges = async (customerId) => {
        let body = { customerId };
        const response = await Http.request({ uri: `/payments/${customerId}/cancel-unregistered-charges`, body });
        if (response.notification) {
            Notifier(response.notification);
        }
        fetchPackages();
    };

    const markDelivered = async (packageId) => {
        let body = { packageId, fieldName: 'movementStatus', fieldValue: 'delivered' };
        const response = await Http.request({ uri: `/packages/${packageId}/set-status`, body });
        if (response.notification) {
            Notifier(response.notification);
        }
        fetchPackages();
    };

    const addToDeliveryQueue = async (packageId) => {
        let body = { packageId, fieldName: 'movementStatus', fieldValue: 'delivering' };
        const response = await Http.request({ uri: `/packages/${packageId}/set-status`, body });
        if (response.notification) {
            Notifier(response.notification);
        }
        fetchPackages();
    };

    const selectFile = async e => {
        if (e) e.preventDefault();
        let fileList = e.target.files;
        let files = [];
        let fileCount = 1;
        for (let key in fileList) {
            let file = fileList[key];
            if (file.name && file.size && fileCount === 1) {
                fileCount++;
                files.push(file);
            }
        }

        const formData = new FormData();
        formData.append('invoiceBinary', files[0]);

        setSelectedFileName(files[0].name);
        setHasFile(true);

        await axios.post(window.getServiceEndpoint() + `/customers/${customerId}/add-invoice`, formData, window.getAxiosConfig())
            .then(result => {
                if (typeof result.data !== 'undefined') {
                }
                if (result.data.notification) {
                    Notifier(result.data.notification);
                }
            }).catch(e => {
                if (e.data.notification) {
                    Notifier(e.data.notification);
                }
            });
    };

    const handleEnterKey = e => {
        if (e.key === 'Enter') {
            saveMemberInfo();
        }
    }

    const [assignment, setAssignment] = useState({});
    const handleChangeAssign = e => setAssignment({ ...assignment, [e.target.name]: e.target.value });

    const submitBoxAssignment = async () => {
        let body = { ...assignment, customerId };
        const response = await Http.request({ uri: `/customers/assign`, body });
        if (response.notification) {
            Notifier(response.notification);
        }
        if (response.result === 'success') {
            fetchMember();
        }
    };

    const [creditPosting, setCreditPosting] = useState({});
    const [debitPosting, setDebitPosting] = useState({});
    const handleChangeCreditPosting = e => setCreditPosting({ ...creditPosting, [e.target.name]: e.target.value });
    const handleChangeDebitPosting = e => setDebitPosting({ ...debitPosting, [e.target.name]: e.target.value });

    const submitCreditPosting = async () => {
        let body = creditPosting;
        const response = await Http.request({ uri: `/payments/${customerId}/post-credit`, body });
        if (response.notification) {
            Notifier(response.notification);
        }
        if (response.result === 'success') {
        }
    };

    const submitDebitPosting = async () => {
        let body = debitPosting;
        const response = await Http.request({ uri: `/payments/${customerId}/post-debit`, body });
        if (response.notification) {
            Notifier(response.notification);
        }
        if (response.result === 'success') {
        }
    };

    const updateCharges = async () => {
        let body = {};
        const response = await Http.request({ uri: `/payments/${customerId}/update-charges`, body });
        if (response.notification) {
            Notifier(response.notification);
        }
        if (response.result === 'success') {
        }
    };

    const updateAllCharges = async () => {
        let body = {};
        const response = await Http.request({ uri: `/payments/${customerId}/update-all-charges`, body });
        if (response.notification) {
            Notifier(response.notification);
        }
        if (response.result === 'success') {
        }
    };

    const [statusInfo, setStatusInfo] = useState({});

    const updateStatus = async field => {
        let body = { fieldName: field, fieldValue: memberInfo[field] };
        const response = await Http.request({ uri: `/customers/${customerId}/set-status`, body });
        if (response.notification) {
            Notifier(response.notification);
        }
        if (response.result === 'success') {
        }
    };

    const setInvoiceStatus = invoiceStatus => setMemberInfo({ ...memberInfo, invoiceStatus });
    const setMovementStatus = movementStatus => setMemberInfo({ ...memberInfo, movementStatus });
    const setPaymentStatus = paymentStatus => setMemberInfo({ ...memberInfo, paymentStatus });
    const setEtaStatus = etaStatus => setMemberInfo({ ...memberInfo, etaStatus });

    const transactionStatuses = [
        { "label": "(empty)", "value": "" },
        { "label": "New", "value": "new" },
        { "label": "Completed", "value": "completed" },
        { "label": "Failed", "value": "failed" },
        { "label": "Cancelled", "value": "cancelled" },
        { "label": "Pending", "value": "pending" },
    ];

    const transactionChannels = [
        { "label": "(empty)", "value": "" },
        { "label": "Card", "value": "card" },
        { "label": "Cash", "value": "cash" },
        { "label": "Bank Transfer", "value": "transfer" },
        { "label": "Lynk", "value": "lynk" },
        { "label": "Paypal", "value": "paypal" },
        { "label": "System", "value": "system" },
    ];

    const getStatusName = status => {
        return transactionStatuses.filter(item => item.value === status)[0]?.label;
    }

    const getTransactionChannel = status => {
        return transactionChannels.filter(item => item.value === status)[0]?.label;
    }

    const [pagination, setPagination] = useState({ packages: {}, transactions: {} });
    const [keywords, setKeywords] = useState({});
    const onChangeKeywords = {
        packages: e => setKeywords({ ...keywords, packages: e.target.value }),
        transactions: e => setKeywords({ ...keywords, transactions: e.target.value }),
    };

    const updatePaging = {
        packages: packages => {
            console.log('paging call', packages)
            setPagination({ ...pagination, packages });
        },
        transactions: transactions => {
            console.log('paging call', transactions)
            setPagination({ ...pagination, transactions });
        }
    }

    let paging = {};

    paging.packages = <Paging
        pagination={pagination.packages}
        pagingUpdate={updatePaging.packages}
        pagingAction={fetchPackages}
        searchUpdate={onChangeKeywords.packages}
        keywords={keywords.packages}
    />

    paging.transactions = <Paging
        pagination={pagination.transactions}
        pagingUpdate={updatePaging.transactions}
        pagingAction={fetchTransactions}
        searchUpdate={onChangeKeywords.transactions}
        keywords={keywords.transactions}
    />

    const emptyRows = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}];

    return (
        <>
            <div className="flex min-h-full">
                <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-col lg:px-20 xl:px-24">
                    <Header title={"Member Management"}></Header>
                    <br />
                    <Container>

                        <div className="grid lg:grid-cols-4 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 gap-6">

                            <div className={"col-span-4"}>

                                <div className="sm:hidden">
                                    <label htmlFor="tabs" className="sr-only">
                                        <Translate text={"Select a tab"} />
                                    </label>
                                    {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                                    <select
                                        id="tabs"
                                        name="tabs"
                                        className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                                        defaultValue={tabs.find((tab) => tab.current).name}
                                    >
                                        {tabs.map((tab) => (
                                            <option key={tab.name}>{tab.name}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className="hidden sm:block">
                                    <nav className="isolate flex divide-x divide-gray-200 rounded-lg shadow" aria-label="Tabs">
                                        {tabs.map((tab, tabIdx) => (
                                            <a
                                                key={tab.name}
                                                href={tab.href}
                                                className={classNames(
                                                    tab.current ? 'text-gray-900 bg-blue-200' : 'text-gray-500 hover:text-gray-700',
                                                    tabIdx === 0 ? 'rounded-l-lg' : '',
                                                    tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                                                    'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10'
                                                )}
                                                aria-current={tab.current ? 'page' : undefined}
                                            >
                                                <span>{tab.name}</span>
                                                <span
                                                    aria-hidden="true"
                                                    className={classNames(
                                                        tab.current ? 'bg-indigo-500' : 'bg-transparent',
                                                        'absolute inset-x-0 bottom-0 h-0.5'
                                                    )}
                                                />
                                            </a>
                                        ))}
                                    </nav>
                                </div>

                                <br />

                                {(page === 'details' || page === '' || !page) &&
                                    <>
                                        <div className={""} onKeyPress={e => handleEnterKey(e)}>

                                            <div className={'page-sub-title flex space-x-8'}>
                                                <div>
                                                    <Translate text={'Member Details'} />
                                                </div>
                                                <div>
                                                    {memberInfo.firstName ? <VerificationStatus customerData={
                                                        {
                                                            name: `${[memberInfo.firstName, memberInfo.lastName].filter(i => i).map(i => i.trim()).join(' ')}`,
                                                            emailAddress: memberInfo.emailAddress,
                                                            phoneNumber: memberInfo.phoneNumber
                                                        }}
                                                        services={['identity']}
                                                    /> : null}
                                                </div>
                                                <div className={'text-gray-500 text-right flex-grow'}> BOX-{memberInfo.boxNumber}
                                                </div>
                                            </div>

                                            <br />
                                            <hr />
                                            <br />

                                            <div className="grid lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 gap-6">

                                                <div className={'col-span-1'}>
                                                    <label htmlFor="firstName"
                                                        className="block text-sm font-medium text-gray-700 form-field-label">
                                                        <Translate text={"First name"} />
                                                    </label>
                                                    <div className="mt-1">
                                                        <Input
                                                            id="firstName"
                                                            name="firstName"
                                                            type="text"
                                                            className={"form-field-input"}
                                                            value={memberInfo.firstName}
                                                            required={true}
                                                            onChange={e => handleChange(e)}
                                                        />
                                                    </div>
                                                </div>

                                                <div className={'col-span-1'}>
                                                    <label htmlFor="middleName"
                                                        className="block text-sm font-medium text-gray-700 form-field-label">
                                                        <Translate text={"Middle name"} />
                                                    </label>
                                                    <div className="mt-1">
                                                        <Input
                                                            id="middleName"
                                                            name="middleName"
                                                            type="text"
                                                            className={"form-field-input"}
                                                            value={memberInfo.middleName}
                                                            required={true}
                                                            onChange={e => handleChange(e)}
                                                        />
                                                    </div>
                                                </div>

                                                <div className={'col-span-1'}>
                                                    <label htmlFor="lastName"
                                                        className="block text-sm font-medium text-gray-700 form-field-label">
                                                        <Translate text={"Last name"} />
                                                    </label>
                                                    <div className="mt-1">
                                                        <Input
                                                            id="lastName"
                                                            name="lastName"
                                                            type="text"
                                                            className={"form-field-input"}
                                                            value={memberInfo.lastName}
                                                            required={true}
                                                            onChange={e => handleChange(e)}
                                                        />
                                                    </div>
                                                </div>

                                            </div>

                                            <br />

                                            <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 gap-6">

                                                <div>
                                                    <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700 form-field-label">
                                                        <Translate text={"Phone Number"} />
                                                    </label>
                                                    <div className="mt-1">
                                                        <Input
                                                            id="phoneNumber"
                                                            name="phoneNumber"
                                                            type="text"
                                                            className={"form-field-input"}
                                                            value={memberInfo.phoneNumber}
                                                            required={true}
                                                            onChange={e => handleChange(e)}
                                                        />
                                                    </div>
                                                </div>

                                                <div>
                                                    <label htmlFor="emailAddress" className="block text-sm font-medium text-gray-700 form-field-label">
                                                        <Translate text={"Email Address"} />
                                                    </label>
                                                    <div className="mt-1">
                                                        <Input
                                                            id="emailAddress"
                                                            name="emailAddress"
                                                            type="text"
                                                            className={"form-field-input"}
                                                            value={memberInfo.emailAddress}
                                                            required={true}
                                                            onChange={e => handleChange(e)}
                                                        />
                                                    </div>
                                                </div>

                                            </div>

                                            <br />

                                            <div>
                                                <div>
                                                    <button
                                                        type="submit"
                                                        className="rounded-md border border-transparent bg-blue-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                                        onClick={() => saveMemberInfo()}
                                                    >
                                                        <Translate text={"Save"} />
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                    </>
                                }

                                {page === 'statement' &&
                                    <>
                                        <div className={""} onKeyPress={e => handleEnterKey(e)}>

                                            <h2 className={'page-sub-title'}>
                                                <Translate text={'Account Statement'} />
                                            </h2>

                                            <br />
                                            <hr />
                                            <br />

                                            <div
                                                className="grid lg:grid-cols-4 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 gap-6">

                                                <div>
                                                    <p className={'text-blue-800'}><strong><Translate text={'Balance'} /></strong></p>
                                                    <p className={'py-3'}>
                                                        <CurrencyText value={memberInfo.statement?.balance} />
                                                    </p>
                                                </div>

                                                <div>
                                                    <p className={'text-blue-800'}><strong><Translate text={'Credit'} /></strong></p>
                                                    <p className={'py-3'}>
                                                        <CurrencyText value={memberInfo.statement?.suspense} />
                                                    </p>
                                                </div>
                                                <div>
                                                    <p className={'text-green-800'}><strong><Translate text={'Debit'} /></strong></p>
                                                    <p className={'py-3'}>
                                                        <CurrencyText value={memberInfo.statement?.debit} />
                                                    </p>
                                                </div>

                                                <div>
                                                    <p className={'text-green-800'}><strong><Translate text={'Reward Points'} /></strong></p>
                                                    <p className={'py-3'}>
                                                        <NumberText value={memberInfo.statement?.points} />
                                                    </p>
                                                </div>


                                            </div>

                                            <br />
                                            <br />

                                        </div>
                                    </>
                                }

                                {page === 'packages' &&
                                    <>
                                        <div className={''}>

                                            <h2 className={'page-sub-title'}>
                                                <Translate text={'Packages for'} />{' '}
                                                <strong>{memberInfo.firstName}{' '}{memberInfo.lastName}</strong>
                                            </h2>

                                            <br />
                                            <hr />
                                            <br />

                                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                                {paging.packages}
                                                <table className="min-w-full divide-y divide-gray-300">
                                                    <thead className="bg-white divide-y divide-x divide-gray-200">
                                                        <tr className={'divide-x divide-gray-300'}>
                                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-md font-semibold text-gray-900 sm:pl-6">
                                                                <Translate text={"APN"} />
                                                            </th>
                                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-md font-semibold text-gray-900 sm:pl-6">
                                                                <Translate text={"Handler#"} />
                                                            </th>
                                                            <th scope="col" className="px-3 py-4 text-left text-md font-semibold text-gray-900">
                                                                <Translate text={"Title"} />
                                                            </th>
                                                            <th scope="col" className="px-3 py-4 text-left text-md font-semibold text-gray-900">
                                                                <Translate text={"Tracking"} />
                                                            </th>
                                                            <th scope="col" className="px-3 py-4 text-left text-md font-semibold text-gray-900">
                                                                <Translate text={"Weight"} />
                                                            </th>
                                                            <th scope="col" className="px-3 py-4 text-left text-md font-semibold text-gray-900">
                                                                <Translate text={"Status"} />
                                                            </th>
                                                            <th scope="col" className="px-3 py-4 text-left text-md font-semibold text-gray-900">
                                                                <Translate text={"Balance"} />
                                                            </th>
                                                            <th scope="col" className="px-3 py-4 text-left text-md font-semibold text-gray-900">
                                                                <Translate text={"Date"} />
                                                            </th>
                                                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                                <span className="sr-only">&nbsp;</span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="divide-y divide-x divide-gray-200">
                                                        {packages.map((packageInfo, packageIndex) => (
                                                            <tr key={packageInfo.packageNumber} className={`divide-x divide-gray-300 ${packageIndex % 2 === 0 ? undefined : 'bg-white'}`}>
                                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                                    <a href={`/packages/${packageInfo._id}/manage`}>{packageInfo.packageNumber}</a>
                                                                </td>
                                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700">{packageInfo.handlerId}</td>
                                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700"><ShortenInText text={packageInfo.title} maxLength={24} /></td>
                                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700"><ShortenInText text={packageInfo.trackingNumber} />{packageInfo.trackingNumber.indexOf('NO-TRACKING') < 0 && <>&nbsp;<a href={`https://parcelsapp.com/en/tracking/${packageInfo.trackingNumber}`} target={"_blank"} title={"Track this Package"}><EyeIcon height={"16"} className={"inline"} /></a></>}</td>
                                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700">{packageInfo.weight} {packageInfo.weight !== 1 ? 'lbs' : 'lb'} </td>
                                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700">{packageInfo.movementStatus}</td>
                                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700"><CurrencyText value={packageInfo.totalBalance} /></td>
                                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700"><DateText value={packageInfo.createdAt} /></td>
                                                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                                    <Menu as="div" className="relative inline-block text-left" style={{ zIndex: 10000 }}>
                                                                        <div>
                                                                            <Menu.Button className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                                                                                <span className="sr-only">Open options</span>
                                                                                <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                                                            </Menu.Button>
                                                                        </div>

                                                                        <Transition
                                                                            as={Fragment}
                                                                            enter="transition ease-out duration-100"
                                                                            enterFrom="transform opacity-0 scale-95"
                                                                            enterTo="transform opacity-100 scale-100"
                                                                            leave="transition ease-in duration-75"
                                                                            leaveFrom="transform opacity-100 scale-100"
                                                                            leaveTo="transform opacity-0 scale-95"
                                                                        >
                                                                            <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                                <div className="py-1">
                                                                                    <Menu.Item>
                                                                                        {({ active }) => (
                                                                                            <a
                                                                                                href={`/packages/${packageInfo._id}/manage`}
                                                                                                className={classNames(
                                                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                    'block px-4 py-2 text-sm'
                                                                                                )}
                                                                                            >
                                                                                                Open
                                                                                            </a>
                                                                                        )}
                                                                                    </Menu.Item>
                                                                                    <Menu.Item>
                                                                                        {({ active }) => (
                                                                                            <a
                                                                                                href="#"
                                                                                                className={classNames(
                                                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                    'block px-4 py-2 text-sm'
                                                                                                )}
                                                                                                onClick={() => applyCharges(packageInfo._id)}
                                                                                            >
                                                                                                Apply Charges
                                                                                            </a>
                                                                                        )}
                                                                                    </Menu.Item>
                                                                                    <Menu.Item>
                                                                                        {({ active }) => (
                                                                                            <a
                                                                                                href="#"
                                                                                                className={classNames(
                                                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                    'block px-4 py-2 text-sm'
                                                                                                )}
                                                                                                onClick={() => applyAllCharges(packageInfo.customerId)}
                                                                                            >
                                                                                                Apply All Charges
                                                                                            </a>
                                                                                        )}
                                                                                    </Menu.Item>
                                                                                    <Menu.Item>
                                                                                        {({ active }) => (
                                                                                            <a
                                                                                                href="#"
                                                                                                className={classNames(
                                                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                    'block px-4 py-2 text-sm'
                                                                                                )}
                                                                                                onClick={() => cancelCharges(packageInfo._id)}
                                                                                            >
                                                                                                Cancel Charges
                                                                                            </a>
                                                                                        )}
                                                                                    </Menu.Item>
                                                                                    <Menu.Item>
                                                                                        {({ active }) => (
                                                                                            <a
                                                                                                href="#"
                                                                                                className={classNames(
                                                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                    'block px-4 py-2 text-sm'
                                                                                                )}
                                                                                                onClick={() => cancelAllCharges(packageInfo.customerId)}
                                                                                            >
                                                                                                Cancel All Charges
                                                                                            </a>
                                                                                        )}
                                                                                    </Menu.Item>
                                                                                    <Menu.Item>
                                                                                        {({ active }) => (
                                                                                            <a
                                                                                                href="#"
                                                                                                className={classNames(
                                                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                    'block px-4 py-2 text-sm'
                                                                                                )}
                                                                                                onClick={() => markDelivered(packageInfo._id)}
                                                                                            >
                                                                                                Mark Delivered
                                                                                            </a>
                                                                                        )}
                                                                                    </Menu.Item>
                                                                                    <Menu.Item>
                                                                                        {({ active }) => (
                                                                                            <a
                                                                                                href="#"
                                                                                                className={classNames(
                                                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                    'block px-4 py-2 text-sm'
                                                                                                )}
                                                                                                onClick={() => addToDeliveryQueue(packageInfo._id)}
                                                                                            >
                                                                                                Add to Delivery Queue
                                                                                            </a>
                                                                                        )}
                                                                                    </Menu.Item>
                                                                                    {packageInfo.invoiceFileKey &&
                                                                                        <Menu.Item>
                                                                                            {({ active }) => (
                                                                                                <a
                                                                                                    href={`${window.getServiceEndpoint()}/public/fs/bucket/${packageInfo.invoiceFileKey}?${window.getQueryStringHeaders()}`}
                                                                                                    className={classNames(
                                                                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                        'block px-4 py-2 text-sm'
                                                                                                    )}
                                                                                                >
                                                                                                    View Invoice
                                                                                                </a>
                                                                                            )}
                                                                                        </Menu.Item>
                                                                                    }
                                                                                    {!packageInfo.invoiceFileKey &&
                                                                                        <Menu.Item>
                                                                                            {({ active }) => (
                                                                                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;No invoice is here</span>
                                                                                            )}
                                                                                        </Menu.Item>
                                                                                    }
                                                                                </div>
                                                                            </Menu.Items>
                                                                        </Transition>
                                                                    </Menu>

                                                                </td>
                                                            </tr>
                                                        ))}
                                                        {emptyRows.map((emptyRow, i) => (
                                                            <tr key={i + packages.length} className={`divide-x divide-gray-300 ${(i + packages.length) % 2 === 0 ? undefined : 'bg-white'}`}>
                                                                <td>&nbsp;</td>
                                                                <td>&nbsp;</td>
                                                                <td>&nbsp;</td>
                                                                <td>&nbsp;</td>
                                                                <td>&nbsp;</td>
                                                                <td>&nbsp;</td>
                                                                <td>&nbsp;</td>
                                                                <td>&nbsp;</td>
                                                                <td>&nbsp;</td>
                                                                <td>&nbsp;</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                <br />
                                                {pagination.packages.count > 10 && <>{paging.packages}</>}

                                            </div>

                                        </div>
                                    </>
                                }

                                {page === 'transactions' &&
                                    <>
                                        <div className={''}>

                                            <h2 className={'page-sub-title'}>
                                                <Translate text={'Transactions for'} />{' '}
                                                <strong>{memberInfo.firstName}{' '}{memberInfo.lastName}</strong>
                                            </h2>

                                            <br />
                                            <hr />
                                            <br />

                                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                                {paging.transactions}
                                                <table className="min-w-full divide-y divide-gray-300">
                                                    <thead className="bg-white divide-y divide-x divide-gray-200">
                                                        <tr className={'divide-x divide-gray-300'}>
                                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-md font-semibold text-gray-900 sm:pl-6">
                                                                #
                                                            </th>
                                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-md font-semibold text-gray-900 sm:pl-6">
                                                                <Translate text={"BOX"} />
                                                            </th>
                                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-md font-semibold text-gray-900 sm:pl-6">
                                                                <Translate text={"Amount"} />
                                                            </th>
                                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-md font-semibold text-gray-900 sm:pl-6">
                                                                <Translate text={"Type"} />
                                                            </th>
                                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-md font-semibold text-gray-900 sm:pl-6">
                                                                <Translate text={"Channel"} />
                                                            </th>
                                                            <th scope="col" className="px-3 py-4 text-left text-md font-semibold text-gray-900">
                                                                <Translate text={"Status"} />
                                                            </th>
                                                            <th scope="col" className="px-3 py-4 text-left text-md font-semibold text-gray-900">
                                                                <Translate text={"Created"} />
                                                            </th>
                                                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                                <span className="sr-only">&nbsp;</span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="divide-y divide-x divide-gray-200">
                                                        {transactions.map((transaction, b) => (
                                                            <tr key={transaction.transactionNumber} className={`divide-x divide-gray-300 ${b % 2 === 0 ? undefined : 'bg-white'}`}>
                                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                                    {transaction.transactionNumber}
                                                                </td>
                                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                                    {transaction.boxNumber}
                                                                </td>
                                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                                    <CurrencyText value={transaction.amount} />
                                                                </td>
                                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                                    {transaction.type?.toTitleCase()}
                                                                </td>
                                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                                    {getTransactionChannel(transaction.channel)}
                                                                </td>
                                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700">{getStatusName(transaction.status)}{' '}{transaction.running &&
                                                                    <span style={{ color: '#32d62f' }} title={"Running Now"}>●</span>}</td>
                                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700"><DateText value={transaction.createdAt} /></td>
                                                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                                    <a href={`/payments/${transaction._id}/manage`}>
                                                                        <Translate text={"Edit"} /><span className="sr-only">, {transaction.transactionNumber}</span>
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                <br />
                                                {pagination.transactions.count > 10 && <>{paging.transactions}</>}
                                            </div>

                                        </div>
                                    </>
                                }

                                {page === 'addresses' &&
                                    <>
                                        <div className={''}>

                                            <h2 className={'page-sub-title'}>
                                                <Translate text={'Delivery Address'} />
                                            </h2>

                                            <br />
                                            <hr />
                                            <br />

                                            <div
                                                className="grid lg:grid-cols-4 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 gap-6">

                                                <div>
                                                    <p className={'text-blue-800'}><strong><Translate text={'Address'} /></strong></p>
                                                    <p className={'py-3'}>
                                                        {memberInfo.deliveryAddress?.addressLine1}<br />
                                                        {memberInfo.deliveryAddress?.addressLine2}<br />
                                                    </p>
                                                </div>

                                                <div>
                                                    <p className={'text-blue-800'}><strong><Translate text={'Town/City/District'} /></strong></p>
                                                    <p className={'py-3'}>
                                                        {memberInfo.deliveryAddress?.addressTown}
                                                    </p>
                                                </div>

                                                <div>
                                                    <p className={'text-blue-800'}><strong><Translate text={'Parish'} /></strong></p>
                                                    <p className={'py-3'}>
                                                        {memberInfo.deliveryAddress?.addressParish}
                                                    </p>
                                                </div>

                                                <div>
                                                    <p className={'text-blue-800'}><strong><Translate text={'Phone Number'} /></strong></p>
                                                    <p className={'py-3'}>
                                                        {memberInfo.phoneNumber}
                                                    </p>
                                                </div>

                                            </div>

                                        </div>
                                    </>
                                }

                                {page === 'billing' &&
                                    <>
                                        <div className={''}>

                                            <h2 className={'page-sub-title'}>
                                                <Translate text={'Billing Actions'} />
                                            </h2>

                                            <br />
                                            <hr />
                                            <br />

                                            {!customerId &&
                                                <>
                                                    <Translate
                                                        text={"Billing actions cannot performed because this package is unassigned. Who would pay the charges? You? Lol."} />
                                                </>
                                            }

                                            {customerId &&
                                                <>
                                                    <p className={'text-sm text-gray-700'}>
                                                        <Translate
                                                            text={"Please select a billing action below to perform on this package and/or Member."} />
                                                    </p>

                                                    <br />

                                                    <div
                                                        className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 gap-6">

                                                        <div>
                                                            <p><strong><Translate text={'Update Charges'} /></strong></p>
                                                            <p className={'text-gray-600 py-2'}>
                                                                <Translate
                                                                    text={"Assess all the details on this package and apply charges to this package only."} />
                                                            </p>
                                                            <p className={'py-2'}>
                                                                <button className={'file-chooser-label'}
                                                                    onClick={() => updateCharges()}><PlayIcon
                                                                        className={'inline'} height={"16"} /> <Translate
                                                                        text={"Update charges"} /></button>
                                                            </p>
                                                        </div>

                                                        <div>
                                                            <p><strong><Translate text={'Update All Charges'} /></strong></p>
                                                            <p className={'text-gray-600 py-2'}>
                                                                <Translate
                                                                    text={"Assess all the details on this package and all other customers for Member and apply charges."} />
                                                            </p>
                                                            <p className={'py-2'}>
                                                                <button className={'file-chooser-label'}
                                                                    onClick={() => updateAllCharges()}><PlayIcon
                                                                        className={'inline'} height={"16"} /> <Translate
                                                                        text={"Update all charges"} /></button>
                                                            </p>
                                                        </div>

                                                        <div>
                                                            <p><strong><Translate text={'Post Credit'} /></strong></p>
                                                            <p className={'text-gray-600 py-2'}>
                                                                <Translate
                                                                    text={"Post a credit amount to the Member's account. A transaction will be created for the Member to see."} />
                                                            </p>
                                                            <div className={'py-2'}>
                                                                <div>
                                                                    <Input
                                                                        id="amount"
                                                                        name="amount"
                                                                        type="text"
                                                                        className={"form-field-input"}
                                                                        value={creditPosting.amount}
                                                                        required={true}
                                                                        onChange={e => handleChangeCreditPosting(e)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <p className={'py-2'}>
                                                                <button className={'file-chooser-label'}
                                                                    onClick={() => submitCreditPosting()}><PlayIcon
                                                                        className={'inline'} height={"16"} /> <Translate
                                                                        text={"Post credit"} /></button>
                                                            </p>
                                                        </div>

                                                        <div>
                                                            <p><strong><Translate text={'Post Debit'} /></strong></p>
                                                            <p className={'text-gray-600 py-2'}>
                                                                <Translate
                                                                    text={"Post a debit amount to the Member's account. A transaction will be created for the Member to see."} />
                                                            </p>
                                                            <div className={'py-2'}>
                                                                <div>
                                                                    <Input
                                                                        id="debitAmount"
                                                                        name="amount"
                                                                        type="text"
                                                                        className={"form-field-input"}
                                                                        value={debitPosting.amount}
                                                                        required={true}
                                                                        onChange={e => handleChangeDebitPosting(e)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <p className={'py-2'}>
                                                                <button className={'file-chooser-label'}
                                                                    onClick={() => submitDebitPosting()}><PlayIcon
                                                                        className={'inline'} height={"16"} /> <Translate
                                                                        text={"Post debit"} /></button>
                                                            </p>
                                                        </div>

                                                    </div>
                                                </>
                                            }

                                        </div>
                                    </>
                                }

                            </div>

                        </div>

                    </Container>
                </div>
            </div>
        </>
    );
}